@import "assets/style/_index.scss";


























































































































































































.status {
    text-align: center;
    background-color: $color-component-bgc;
    padding: $padding-big * 3 $padding-big;
    margin-bottom: $margin;
    > .label {
        margin-top: $margin-big;
    }
}
