@import "assets/style/_index.scss";



































































































































































































































































































.goods-info {
    background-color: #fff;
    padding: $padding;
    > .name {
        font-weight: bold;
        margin-bottom: $margin;
    }
}
