@import "assets/style/_index.scss";




































































































































































.course-info {
    padding: $padding;
    background-color: $color-component-bgc;
    > .label {
        font-weight: bold;
    }
    > .info {
        text-align: right;
        color: $color-text-minor;
        margin-top: $margin;
    }
}
