@import "assets/style/_index.scss";


































































































.apply-practical-info-wrap {
    margin-top: $margin;
    font-size: $little * 100%;
    @include bodyDl(4em);
}
