@import "assets/style/_index.scss";














































.qrcode-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: $margin-big * 5;

    > .header {
        margin-bottom: $margin-big;
        > .label {
            font-size: $big * 100%;
            font-weight: bold;
            margin-bottom: $margin-small;
        }
    }
    > .footer {
        margin-top: $margin-big;
        > .desc {
            margin-top: $margin;
            color: $color-text-minor;
        }
    }
}
