@import "assets/style/_index.scss";
































































































































































































.refund-detail-apply-info {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    overflow: hidden;
    margin-bottom: $margin;

    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
}
