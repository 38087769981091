@import "assets/style/_index.scss";








































































































.survey-topic {
    font-size: 36px;
    line-height: 1.5;
    background-color: $color-component-bgc;
    overflow: hidden;
    > .header,
    > .body,
    > .footer {
        margin: $padding;
    }

    > .header {
        > .label {
            font-weight: bold;
            margin-top: $margin;
            font-size: 110%;
        }
    }
    > .body {
        > .item {
            //margin: $margin 0;
            + .item {
                margin-top: -1px;
                border-top: 1px solid $color-border;
            }
        }
    }
    > .footer {
        > .result {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        > .analysis {
            margin-top: $margin;
        }
    }
}
