@import "assets/style/_index.scss";

























































































































































































.signature-wrap {
    padding: $padding;
    > .x-button {
        + .x-button {
            margin-left: $margin-small;
        }
    }
    .desc {
        font-size: $little * 100%;
        color: $color-text-minor;
        margin-top: $margin-small;
    }
}
