@import "assets/style/_index.scss";



































































































































































































































.banner {
    flex: 0 0 auto;
    text-align: center;
    padding: $padding-big;
    > .label {
        text-align: center;
        font-size: $super * 100%;
        margin-top: $margin-big;
    }
    > .desc {
        margin-top: $margin-small;
    }
}

.footer-ctrl {
    padding: $padding $padding-big;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    //font-size: $small * 100%;
}

.copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $padding-big;
    text-align: center;
    font-size: $small * 100%;
    opacity: 0.3;
}
