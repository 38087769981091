@import "assets/style/_index.scss";































































.main-tab-bar {
    background-color: #fff;
    border-top: 0 !important;
    > .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $gray6;
        > .x-icon {
            font-size: 40px;
        }
        > .label {
            //font-size: 0.6 * 100%;
            //margin-top: $margin-small;
        }
        &.active {
            color: $color-main;
        }
    }
}
