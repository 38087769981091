@import "assets/style/_index.scss";







































































































































































.import-student {
    flex: 1 1 1%;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1;
            text-align: right;
        }
        > .x-icon {
            flex: 0 0 auto;
        }

        &.noop {
            color: $gray6;
        }
    }
}
.import-student-popup {
    .download-template-box {
        padding: $padding-small $padding;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: $little * 100%;
            background-color: mix(#fff, $color-primary, 80%);
            padding: $padding-small $padding;
            border: 1px solid $color-primary;
            border-radius: $padding-small;
        }
    }
}
