@import "assets/style/_index.scss";























































































.statement-item {
    background-color: $color-component-bgc;
    padding: $padding;
    > .header {
        > .label {
            font-weight: bold;
            font-size: 110%;
        }
    }
    > .body {
        margin-top: $margin;
        @include bodyDl(4em);
    }
    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
