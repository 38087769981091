@import "assets/style/_index.scss";

























































.user-box {
    display: block;
    background-color: $color-main;
    padding: $padding-big $padding;
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding-small $padding-big;
        margin-bottom: $margin;
        /*.avatar {
            flex: 0 0 auto;
            width: 150px;
            height: 150px;
            border-radius: 100vw;
            border: 1px solid #fff;
        }*/
        .info {
            flex: 1 1 1%;
            margin-left: $margin;
            line-height: 1.5;
            color: #fff;

            > .account,
            > .nickname {
                font-size: $little * 100%;
            }
        }
    }
}
