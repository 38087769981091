@import "assets/style/_index.scss";














































































































































.upload-wrap {
    padding: $padding-big * 2 $padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $color-component-bgc;
    > .label {
        margin-top: $margin;
    }
    > .desc {
        margin-top: $margin-small;
        //font-size: $little * 100%;
        color: $color-text-minor;
        align-self: stretch;
        text-align: left;

        > ol {
            margin-left: 1.2em;
        }
    }
}
.supplement-materials-item {
    background-color: $color-component-bgc;
    padding: $padding;
    > .header {
        > .label {
            font-weight: bold;
            font-size: 110%;
        }
    }
    > .body {
        margin-top: $margin;
        @include bodyDl(4em);
    }
    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
