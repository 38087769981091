@import "assets/style/_index.scss";
























































.notice-item {
    .sub-label {
        color: $gray6;
        @include text-line(1);
    }
}
