@import "assets/style/_index.scss";








































































































































































































































































































































































































.my-home-page {
    height: 100%;
    @include setScrollAxis('y');
}
