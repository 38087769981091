@import "assets/style/_index.scss";


































































































































































.serve-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    &-content {
        flex: 1;
        @include setScrollAxis('y');
    }
    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .tab {
            flex: 1;
            overflow: hidden;
            ~ .query-bar {
                flex: 0 0 auto;
            }
        }
        > .query-bar {
            flex: 1;
        }
    }
}
