@import "assets/style/_index.scss";




























































































.survey-topic-list {
    //padding: $padding;
    > .item {
        + .item {
            margin-top: $margin;
        }
    }
}
