@import "assets/style/_index.scss";












































































.schedule-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();
    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
}
