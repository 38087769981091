@import "assets/style/_index.scss";






















































































































































































































.paper-list {
    margin-top: $margin;
    > .item {
        background-color: $color-component-bgc;
        padding: $padding;
        + .item {
            border-top: 1px solid $color-border;
        }

        > .label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .count {
                font-weight: bold;
            }
        }
        > .content {
            margin-top: $margin-small;
            font-size: $little * 100%;
            color: $color-text-minor;
        }
    }
}
