@import "assets/style/_index.scss";






















.home-page {
    height: 100%;
    @include setScrollAxis('y');
}
