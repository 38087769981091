@import "assets/style/_index.scss";










































































































































































































.pass-prove-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $padding;
    background-color: $color-component-bgc;
    font-size: $little * 100%;
    > .avatar {
        width: 200px;
        flex: 0 0 auto;
        margin-right: $margin;
    }
    > .content {
        flex: 1 1 1%;
        @include bodyDl(5em);
    }
}
.training-records-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .content {
        flex: 1 1 1%;
        > .label {
        }
        > .extra {
            color: $color-text-minor;
        }
    }
    > .extra {
        margin-left: $margin-small;
    }
}
.face-comparison-dialog-avatar {
    position: relative;
    flex: 0 0 auto;
    > .time {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#000, 0.5);
        color: #fff;
        //font-size: $small * 100%;
        line-height: 1;
        text-align: center;
        padding: $padding-small;
    }
}
