@import "assets/style/_index.scss";





































































































































































































































































































































































































































































.practice-topic-list {
    &.swiper-container {
        height: 100%;
        background-color: $color-component-bgc;
        > .swiper-wrapper {
            > .swiper-slide {
                overflow-y: hidden;
                @include setScrollAxis('y');
            }
        }
    }
}
