@import "assets/style/_index.scss";




























































































































































.main-tab-panel {
    height: 100%;
    > .swiper-wrapper {
        > .swiper-slide {
            //overflow-y: auto;
        }
    }
}
