@import "assets/style/_index.scss";




















































































































.course-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    &-content {
        flex: 1;
        @include setScrollAxis('y');
    }
}
