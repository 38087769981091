@import "assets/style/_index.scss";


























































.only-training-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .inner {
        flex: 1 1 1%;
        > .header {
            margin: $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .x-icon {
                margin-right: $margin-small;
            }
            > .x-label {
                flex: 1;
                font-weight: bold;
            }
        }
        > .body {
            margin: $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .inner {
                flex: 1;
                dl {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    + dl {
                        margin-top: $margin-small;
                    }
                    > dt {
                        flex: 0 0 auto;
                        margin-right: $margin-small;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        @include clearfix;
                        > div {
                            width: 5em;
                            text-align: justify;
                            overflow: hidden;
                            margin-bottom: -($body-font-size * $line-height);
                            float: left;
                            &:after {
                                display: inline-block;
                                content: '';
                                padding-left: 100%;
                            }
                        }
                    }
                    > dd {
                        flex: 1;
                    }
                }
            }
        }
        > .footer {
            border-top: 1px solid $color-border;
        }
    }
    > .ctrl {
        padding: $padding;
    }
}
