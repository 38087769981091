@import "assets/style/_index.scss";



































.enterprise-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: $padding-big * 2 $padding;
    background-color: $color-component-bgc;

    > .name {
        margin-top: $margin-big;
        font-weight: bold;
        font-size: $big * 100%;
    }
    > .sn {
        margin-top: $margin-small;
    }
}
